import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";
import router from "@/router";


export default {
    state: {
        sellpoints: [],
        sellpointsDetails: [],
        sellpointUnpaid: [],
        sellPointCols: [
            {
                label: "اسم نقطة البيع",
                field: "name",
            },
            {
                label: "تاريخ  الإشتراك",
                sortable: true,
                field: "subscribeDate",
            },
            {
                label: "عدد الاكواد",
                type: "number",
                field: "codeCount",
            },
            //{
            //    label: " القيمة المدفوعة ",
            //    type: "number",
            //    field: "paid",
            //},
            //{
            //    label: "  القيمة المستحقة ",
            //    type: "number",
            //    field: "unPaid",
            //},
            {
                label: "  تاريخ آخر دفعة  ",
                sortable: true,
                field: "lastPaidDate",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        DetailSellCols: [
            {
                label: "رقم الفاتورة ",
                type: "number",
                field: "serialNumber",
            },
            {
                label: " تاريخ الفاتورة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "  عدد الرموز",
                type: "number",
                field: "codesCount",
            },
            {
                label: " قيمة الفاتورة ",
                type: "number",
                field: "paid",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
    },
    getters: {
        sell({ sellpointsDetails }, getter, { filter }) {
            return sellpointsDetails.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        },
        sellPointList(state) {
            return state.sellpoints.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        sellPointDetailsList(state) {
            return state.sellpointsDetails.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        sellPointUnpaidList(state) {
            return state.sellpointUnpaid.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
    },
    mutations: {
        Get_SellPoints_List(state, payload) {
            state.sellpoints = payload
        },
        Get_Details_SellPoint_List(state, payload) {
            state.sellpointsDetails = payload
        },
        Get_Unpaid_SellPoint_List(state, payload) {
            state.sellpointUnpaid = payload
        },
    },
    actions: {
        getSellPointsList({ commit }) {
            api.get("Invoice/Dash/GetAllSellPoint", ({ data }) => {
                commit("Get_SellPoints_List", data)
            });
        },
        getDetailsSellPointList({ commit }, sellPointId) {
            api.get(`Invoice/Dash/GetAll?sellPointId=${sellPointId}`, ({ data }) => {
                commit("Get_Details_SellPoint_List", data)
            });
        },
        getUnpaidSellPointList({ commit }, sellPointId) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetUnPaidCodes?sellPointId=${sellPointId}`,
                    ({ data }) => {
                        commit("Get_Unpaid_SellPoint_List", data),
                            resolve(data)
                    },
                );
            })
        },
        addInvoiceSellPoint(_, payload) {
            return new Promise(resolve => {
                api.post(
                    "Invoice/Dash/Add",
                    payload,
                    ({ data }) => {
                        resolve(data)
                        router.push(router.go(-1));
                    },
                    {
                        success: "تم إضافة الفاتورة بنجاح",
                        error: "فشل إضافة الفاتورة",
                    }
                    
                );
            })

        },
        clearInvoiceSellPoint(_, payload) {
            return new Promise(resolve => {
               

                api.post(        
                    `SellPoint/Dash/ClearAccount?sellPointId=${payload}`,
                    payload,
                    ({ data }) => {
                        resolve(data)
                        router.push(router.go(-1));
                    },
                    {
                        confirm: "هل تريد بالتأكيد الارشفة كشف الحساب؟ هذه العميلة غير قابلة للتراجع.",

                        success: "تم إضافة تصفير الحساب بنجاح",
                        error: "فشل إضافة الفاتورة",
                    }
                );
            })

        },
        deleteInvoice(_, sellPointId) {
            api.delete(
                `Invoice/Dash/Delete?sellPointId=${sellPointId}`,
                () => {
                    router.go(-1)
                    
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الفاتورة",
                    success: "تم حذف الفاتورة بنجاح",
                    error: "فشل حذف الفاتورة ..يرجى اعادة المحاولة ",
                }
            );
        },
        getSellPointDetails(_, id) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetById?id=${id}`,
                    ({ data }) => {
                        resolve(data)
                    }
                );
            })

        },
    },
};